
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import TemplateSelect from "@/components/backstories/TemplateSelect.vue"
import {
  KIN_66,
  TemplateItem,
} from "@/data/character_template/character_template"

import { rollKin } from "@/components/backstories/characterTemplate"
import { getAgeType, getAgeRangeArray, getAgeRange } from "@/age"
import { kinTransform } from "@/util/characterUtil"
import { Age, ExtendedKinName } from "@/types"
import { getRandomIntInclusive } from "@/dice"
import { rollAge } from "@/components/backstories/characterTemplate"

@Component({
  components: {
    TemplateSelect,
  },
})
export default class CharacterTemplateChildhood extends Vue {
  @Prop({ required: true }) value!: number
  @Prop({ required: true }) kin!: ExtendedKinName

  setAgeWithValue(value: any) {
    this.$emit("input", value)
  }

  randomClicked() {
    const age = rollAge(kinTransform(this.kin))
    this.setAgeWithValue(age)
  }

  get ageType() {
    return getAgeType(this.value, kinTransform(this.kin))
  }

  get ageRanges() {
    return getAgeRangeArray(kinTransform(this.kin))
  }

  get ageRange() {
    return getAgeRange(kinTransform(this.kin))
  }

  get title() {
    return `${this.$t(this.ageType)}`
  }

  getRange(ageType: Age): string {
    if (ageType === "young") {
      return `${this.ageRanges[0]}-${this.ageRanges[1] - 1}`
    } else if (ageType === "adult") {
      return `${this.ageRanges[1]}-${this.ageRanges[2] - 1}`
    } else if (ageType === "old") {
      return `${this.ageRanges[2]}+`
    }
    return "?-?"
  }
}
