
import Vue from "vue"
import { Component, Watch } from "vue-property-decorator"
import { Skill, Age } from "@/types"

import characterTemplate from "@/data/character_template/character_template"
import { rollDice } from "@/dice"

import {
  rollProfession,
  rollKin,
  rollTalent,
  rollAge,
} from "@/components/backstories/characterTemplate"
import { getAgeType, getStartingTalentsFromType } from "@/age"
import { CharDataQueryObj, kinTransform } from "@/util/characterUtil"

import ChildhoodTemplate from "@/components/backstories/ChildhoodTemplate.vue"
import FormativeEventTemplate from "@/components/backstories/FormativeEventTemplate.vue"
import KinTemplate from "@/components/backstories/KinTemplate.vue"
import AgeTemplate from "@/components/backstories/AgeTemplate.vue"
import ProfessionTemplate from "@/components/backstories/ProfessionTemplate.vue"
import TalentTemplate from "@/components/backstories/TalentTemplate.vue"
import TemplateSelect from "@/components/backstories/TemplateSelect.vue"
import ActionBar from "@/components/base/ActionBar.vue"
import FLButton from "@/components/base/FLButton.vue"

type SkillObj = { [S in Skill]?: number }
function mergeSkills(skills1: SkillObj, skills2: SkillObj) {
  const mergedSkills = { ...skills1 }
  const skills2Entries = Object.entries(skills2) as [Skill, number][]
  skills2Entries.map((skill) => {
    const name = skill[0]
    const value = skill[1]
    if (mergedSkills[name]) {
      ;(mergedSkills[name] as number) += value
    } else {
      mergedSkills[name] = value
    }
  })
  return mergedSkills
}

@Component({
  components: {
    ActionBar,
    AgeTemplate,
    ChildhoodTemplate,
    FLButton,
    FormativeEventTemplate,
    KinTemplate,
    ProfessionTemplate,
    TalentTemplate,
    TemplateSelect,
  },
})
export default class CharacterTemplateView extends Vue {
  // Init values
  selectedKin = rollKin()
  childhoodNum = rollDice(6)
  selectedProfession = rollProfession()
  selectedTalent = rollTalent(this.selectedProfession.id)
  age = rollAge(kinTransform(this.selectedKin.id))
  formativeEvents = this.getFormativeEvents()

  rollAll() {
    this.selectedKin = rollKin()
    this.age = rollAge(kinTransform(this.selectedKin.id))
    this.childhoodNum = rollDice(6)
    this.selectedProfession = rollProfession()
    this.selectedTalent = rollTalent(this.selectedProfession.id)
    this.formativeEvents = this.getFormativeEvents()
  }

  getFormativeEvents() {
    // TODO: Rewrite, avoid multiple loops
    let formativeEvents = Array.from(Array(this.formativeEventNumber)).map(
      (element) => {
        return rollDice(6)
      }
    )
    for (let i = 0; i < this.formativeEventNumber; i++) {
      let diceVal = formativeEvents[i]
      while (formativeEvents.includes(diceVal)) {
        diceVal = rollDice(6)
      }
      formativeEvents[i] = diceVal
    }
    return formativeEvents
  }

  get ageType(): Age {
    const kinId = kinTransform(this.selectedKin.id)
    return getAgeType(this.age, kinId)
  }

  get kin() {
    return kinTransform(this.selectedKin.id)
  }

  get formativeEventNumber() {
    return getStartingTalentsFromType(this.ageType)
  }

  @Watch("ageType", { immediate: true })
  updateFormativeEvent() {
    this.formativeEvents = this.getFormativeEvents()
  }

  get characterTemplateData(): CharDataQueryObj {
    const roll = {
      kinId: this.selectedKin.id,
      professionId: this.selectedProfession.id,
      talentId: this.selectedTalent.id,
      childhoodIndex: String(this.childhoodNum - 1),
      formativeEvents: String(this.formativeEvents),
      age: String(this.age),
    }
    return roll
  }

  backClicked() {
    this.$router.go(-1)
  }

  nextClicked() {
    const query = this.characterTemplateData
    this.$router.push({ name: "character_creator-template-edit", query })
  }
}
