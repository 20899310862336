






































































import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import TemplateSelect from "@/components/backstories/TemplateSelect.vue"
import characterTemplate from "@/data/character_template/character_template"
import { rollDice } from "@/dice"
import { ChildhoodTemplateData } from "src/data/character_template/childhood"

@Component({
  components: {
    TemplateSelect,
  },
})
export default class CharacterTemplateChildhood extends Vue {
  characterTemplate = characterTemplate

  @Prop({ required: true }) selectedKinId!: string
  @Prop({ required: true }) value!: number

  get title() {
    return this.$t(
      characterTemplate.CHILDHOOD[this.selectedKinId][this.value - 1].key
    )
  }
  get description() {
    return this.$t("childhood") + ", " + this.$t(this.selectedKinId)
  }

  childhoodName(childhoodData: ChildhoodTemplateData) {
    return this.$t(childhoodData.key)
  }
  childhoodStory(childhoodData: ChildhoodTemplateData) {
    return this.$t(childhoodData.key + " story")
  }

  emitRandom() {
    this.$emit("input", rollDice(6))
  }

  emit(num: number) {
    this.$emit("input", num)
  }
}
