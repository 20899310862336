





























import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import TemplateSelect from "@/components/backstories/TemplateSelect.vue"
import {
  PROFESSION_66,
  TemplateItem,
} from "@/data/character_template/character_template"

import { rollProfession } from "@/components/backstories/characterTemplate"

@Component({
  components: {
    TemplateSelect,
  },
})
export default class CharacterTemplateChildhood extends Vue {
  PROFESSION_66 = PROFESSION_66
  roll = rollProfession

  @Prop({ required: true }) value!: {
    id: string
    diceRoll: number
    result: TemplateItem
  }

  setKinWithValue(diceVal: number) {
    // professsion is set by 'rolling' with a fixed value
    this.$emit("input", this.roll(diceVal))
  }

  randomClicked() {
    this.$emit("input", this.roll())
  }
}
