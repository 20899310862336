




























































import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import TemplateSelect from "@/components/backstories/TemplateSelect.vue"
import characterTemplate from "@/data/character_template/character_template"
import { rollDice } from "@/dice"
import { FormativeEvent } from "src/data/character_template/formative_events"

@Component({
  components: {
    TemplateSelect,
  },
})
export default class CharacterTemplateChildhood extends Vue {
  characterTemplate = characterTemplate
  rollDice = rollDice

  @Prop({ required: true }) selectedProfessionId!: string
  @Prop({ required: true }) value!: number

  get selectedEventName() {
    return this.$t(
      characterTemplate.FORMATIVE_EVENTS[this.selectedProfessionId][
        (this.value || 1) - 1
      ].key
    )
  }

  eventName(formativeEvent: FormativeEvent) {
    return this.$t(formativeEvent.key)
  }
  eventStory(formativeEvent: FormativeEvent) {
    return this.$t(formativeEvent.key + " story")
  }
  eventItems(formativeEvent: FormativeEvent) {
    return this.$t(formativeEvent.key + " items")
  }

  get description() {
    return (
      this.$t("formative event") + ", " + this.$t(this.selectedProfessionId)
    )
  }

  emitRandom() {
    this.$emit("input", rollDice(6))
  }

  emit(num: number) {
    this.$emit("input", num)
  }
}
