
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import FLButton from "@/components/base/FLButton.vue"
import SvgIcon from "@/components/SvgIcon.vue"
import Card from "@/components/Card.vue"

@Component({
  components: {
    Card,
    FLButton,
    SvgIcon,
  },
})
export default class TemplateSelect extends Vue {
  @Prop({ default: "" }) title!: string
  @Prop({ default: "" }) description!: string
  @Prop({}) diceValue!: number
  @Prop({}) numeralValue!: number

  rollClicked() {
    this.$emit("randomClicked")
  }

  get diceResults() {
    return this.diceValue.toString()
  }
}
