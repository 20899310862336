
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { SET_PAGE_TITLE } from "@/store/store-types"
import CharacterBackstory from "@/components/backstories/CharacterBackstory.vue"

@Component({
  components: {
    CharacterBackstory,
  },
})
export default class CharacterTemplateView extends Vue {
  mounted() {
    this.$store.commit(SET_PAGE_TITLE, "Template")
  }
}
