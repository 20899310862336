






























import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import TemplateSelect from "@/components/backstories/TemplateSelect.vue"
import {
  KIN_66,
  TemplateItem,
} from "@/data/character_template/character_template"

import { rollKin } from "@/components/backstories/characterTemplate"

@Component({
  components: {
    TemplateSelect,
  },
})
export default class CharacterTemplateChildhood extends Vue {
  KIN_66 = KIN_66

  @Prop({ required: true }) value!: {
    id: string
    diceRoll: number
    result: TemplateItem
  }

  setKinWithValue(diceVal: number) {
    // Kin is set by 'rolling' with a fixed value
    this.$emit("input", rollKin(diceVal))
  }

  randomClicked() {
    this.$emit("input", rollKin())
  }
}
