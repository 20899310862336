






























import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import { Profession } from "@/types"

import TemplateSelect from "@/components/backstories/TemplateSelect.vue"
import { rollTalent } from "@/components/backstories/characterTemplate"
import characterTemplate, {
  TemplateItem,
} from "@/data/character_template/character_template"

import { rollDice } from "@/dice"

@Component({
  components: {
    TemplateSelect,
  },
})
export default class CharacterTemplateChildhood extends Vue {
  characterTemplate = characterTemplate

  @Prop({ required: true }) value!: { id: string; diceRoll: number }
  @Prop({ required: true }) professionId!: Profession

  setTalent(talentId: string, index: number) {
    const data = { id: talentId, diceRoll: (1 + index) * 2 - 1 }
    this.$emit("input", data)
  }

  get description() {
    return this.$t("Class talent") + ", " + this.$t(this.professionId)
  }

  randomClicked() {
    this.$emit("input", rollTalent(this.professionId))
  }

  @Watch("professionId")
  onProfessionChange() {
    this.randomClicked()
  }
}
